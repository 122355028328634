<template>
  <div class="table-list">
    <div class="title">
      <div class="title-inner">{{ title }} <slot name="prefix"></slot></div>

      <div class="block-more" @click="handleMore" v-if="showMore">
        查看更多<i class="el-icon-arrow-right "></i>
      </div>
      <slot name="suffix"></slot>
    </div>
    <div  class="table-wrap table">
      <el-table :data="dataList">
      <el-table-column
        v-for="column in columns"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :show-overflow-tooltip="column.showToolTip"
        :key="column.label"
      >
        <template #header v-if="column.header">
          <div>
            {{ $t(column.header) }}
          </div>
        </template>
        <template v-if="column.slot" #default="scope">
          <slot :name="column.slot" :scoped="{ ...scope }"></slot>
        </template>
      </el-table-column>
    </el-table>
      <slot name="pagination"></slot>
    </div>

    <div class="table-wrap m-table">
      <el-table :data="mDataList" >
        <el-table-column
          v-for="column in mColumns"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :show-overflow-tooltip="column.showToolTip"
          :key="column.label"
        >
          <template #header v-if="column.header">
            <div>
              {{ $t(column.header) }}
            </div>
          </template>
          <template v-if="column.slot" #default="scope">
            <slot :name="column.slot" :scoped="{ ...scope }"></slot>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showMore: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default() {
        return []
      }
    },
    mDataList: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    mColumns: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      let obBtn = document.querySelector('.bottom'),
        intersectionObserver = null
      console.log(obBtn)
      if (obBtn) {
        intersectionObserver = new IntersectionObserver((entries) => {
          if (entries[0].intersectionRatio <= 0) return
          if (this.firstTime) {
            this.firstTime = false
            return
          }

          this.$emit('reachBottom')
        })
        // start observing
        intersectionObserver.observe(document.querySelector('.bottom'))
      }
    })
  },
  methods: {
    handleMore() {
      this.$emit('more')
    }
  }
}
</script>

<style lang="less" scoped>
@import "./title.less";
.table-list {
  margin-bottom: 16px;
  padding: 24px;
  background: #ffffff;
  .block-more {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #408fff;
    line-height: 20px;
    cursor: pointer;
  }
}
.table-wrap{
  margin-top: 16px;
}
.m-table{
  display: none;
}
@media screen and (max-width: 1024px) {
.title{
  align-items: flex-start;
  .title-inner{
    display: block;
  }
}
.table{
  display: none;
}
.table-wrap{
  margin-top: 8px;
}
.m-table{
  display: block;
}
.table-list {
  margin-bottom: 8px;
  padding: 16px;
  background: #ffffff;
  .block-more {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #408fff;
    line-height: 20px;
    cursor: pointer;
  }
}
::v-deep .el-table{
  font-size: 14px;
  tr .el-table__cell>.cell{
    padding-left: 0;
    padding-right: 8px;
  }
  th.el-table__cell{
    font-size: 12px;
    padding: 8px 0 4px;
    .cell{
      line-height: 20px;
    }
  }
}
.bottom {
  width: 10px;
  height: 10px;
}
}
</style>
