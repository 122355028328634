const blockColumns = [
    {
      prop: 'blockNumber',
      header: 'table.blockHeight',
      width: '112px'
    },
    {
      prop: 'blockHash',
      header: 'table.hashValue',
      width: '864px',
      slot: 'hash'
    },
    {
      prop: 'txTime',
      header: 'table.linkTime',
      width: '176px'
    }
  ],
  newDataColumns = [
    {
      prop: 'advertiser',
      label: '广告主',
      width: '112px'
    },
    {
      prop: 'flowMain',
      label: '流量主',
      width: '112px'
    },
    {
      prop: 'adUrl',
      label: '流量主地址',
      width: '470px',
      slot: 'adurl'
    },
    {
      prop: '',
      label: '流量主地址 是否可跳转',
      width: '94px',
      slot: 'isJump'
    },
    {
      prop: 'browserSource',
      label: '浏览来源',
      width: '188px'
    },
    {
      prop: 'createdAt',
      label: '生成时间',
      width: '176px'
    }
  ],
  mBlockColumns = [
    {
      prop: 'blockNumber',
      header: 'table.blockHeight',
      width: '64px'
    },
    {
      prop: 'blockHash',
      header: 'table.hashValue',
      slot: 'hash'
    },
    {
      prop: 'txTime',
      header: 'table.linkTime',
      width: '88px'
    }
  ],
  mNewDataColumns = [
    {
      prop: 'advertiser',
      label: '广告主',
      width: '64px'
    },
    {
      prop: 'flowMain',
      label: '流量主',
      width: '64px'
    },
    {
      prop: 'adUrl',
      label: '流量主地址',
      slot: 'adUrl'
    },
    {
      prop: 'createdAt',
      label: '生成时间',
      width: '88px'
    }
  ]

export default {
  blockColumns,
  newDataColumns,
  mBlockColumns,
  mNewDataColumns
}
