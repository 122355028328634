<template>
  <div>
    <tableList
      :dataList="adList"
      :mDataList="mAdList"
      :title="$t('table.newCreateData')"
      :columns="newDataColumns"
      :mColumns="mNewDataColumns"
      @reachBottom="load"
    >
      <template #suffix >
        <div class="total-span">
          共{{allAdCount}}条
        </div>
      </template>
      <template #adurl="{scoped}">
        <div class="text-overflow2">
          <a :href="scoped.row.adUrl" v-if="isCanJump(scoped.row.adUrl)" class="blue" target="_blank">{{
            scoped.row.adUrl
          }}</a>
          <span v-else>{{ scoped.row.adUrl }}</span>
        </div>
      </template>
      <template #adUrl="{scoped}">
        <div class="text-overflow2">
            {{ scoped.row.adUrl }}
        </div>
      </template>
      <template #isJump="{scoped}">
        <div>
          {{ isCanJump(scoped.row.adUrl) ? "是" : "否" }}
        </div>
      </template>
      <template #pagination>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="getAdData"
            :current-page.sync="pagination.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="pagination.size"
            layout="slot, sizes, prev, pager, next"
            :total="pagination.total"
          >
            <span class="total-span mr-24">最近{{pagination.total}}条</span>
          </el-pagination>
        </div>
      </template>
    </tableList>
  </div>
</template>

<script>
import tableList from '@/components/data/tableList.vue'
import { isCanJump } from '@/utils'
import tableConfig from '@/components/data/tableConfig'
import api from '@/api/services/browser'
export default {
  components: {
    tableList
  },
  data() {
    return {
      adList: [],
      mAdList: [],
      newDataColumns: tableConfig.newDataColumns,
      mNewDataColumns: tableConfig.mNewDataColumns,
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      mPagination: {
        page: 2,
        size: 10,
        total: 0,
        totalPage: 0
      },
      allAdCount: 0,
      isPost: false
    }
  },
  created() {
    this.getAdData()
  },
  methods: {
    isCanJump,
    handleSizeChange() {
      this.pagination.page = 1
      this.getAdData()
    },
    load() {
      if (this.mPagination.page < this.mPagination.totalPage) {
        this.getMoblieAdData()
      }
    },
    async getMoblieAdData() {
      if (this.isPost) return false
      this.isPost = true
      let data = await api.getAdData({
        page: this.mPagination.page,
        size: this.mPagination.size
      })
      this.isPost = false
      this.mAdList = this.mAdList.concat(data.adList || [])
      this.mPagination.totalPage = Math.ceil(data.total / this.mPagination.size)
      this.mPagination.page += 1
      this.allAdCount = data.allAdCount
    },
    async getAdData() {
      let data = await api.getAdData({
        page: this.pagination.page,
        size: this.pagination.size
      })
      this.adList = data.adList || []
      this.mAdList = data.adList || []
      this.pagination.total = data.total
      this.mPagination.totalPage = Math.ceil(data.total / this.mPagination.size)
      this.allAdCount = data.allAdCount
    }
  }
}
</script>

<style lang="less" scoped>
</style>
